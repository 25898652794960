/**
 * @author JuanFuent.es
 * @name Cursor
 * @desc 
 */

import {
    _createElement
} from "gsap/CSSPlugin"
import Mouse from "./mouse"
import {
    Triangle,
    Vector2
} from "three"
import {
    lerp
} from "three/src/math/MathUtils"

export default class Cursor {
    constructor() {
        this.mouse = new Mouse()
        this.container = document.createElement("div")
        document.body.appendChild(this.container)
        //
        this.shape = document.createElement("div")
        this.container.appendChild(this.shape)

        this.img = new Image()
        this.img.src = "/static/spaceboy-play-icon.svg"
        this.shape.appendChild(this.img)
        //
        this.position = new Vector2(1, 1)
        this.precision = 2
        this.scale = 1
        this.degrees = 0
        this.lerp = 0.915
        this.bg = "#101010"
        this.border = "#FFF"
        this.size = "100px"
        this.borderSize = "0px"
        this.events()
        this.style()
    }
    
    events() {
        gsap.set(this.img, {
            opacity: 0,
            scaleX: .5,
            scaleY: .5,
        })
        this.setVideoTL()
        document.querySelectorAll(".hero-video").forEach(video => this.addVideoEvent(video))
    }

    setVideoTL() {
        this.videoTL = new gsap.timeline({
            paused: true,
        })
        this.videoTL.to(this.img, {
            duration: .35,
            opacity: 1,
            scaleX: 1,
            scaleY: 1
        }, 0)
    }

    addVideoEvent(_element) {
        _element.addEventListener("mouseenter", () => {
            this.videoTL.duration(.35).play()
        })
        _element.addEventListener("mouseleave", () => {
            this.videoTL.duration(.15).reverse()
        })
    }

    style() {
        let style = `position:fixed;z-index:99;left:0;top:0;pointer-events:none;`
        this.container.setAttribute('style', style)
        let shapeStyle = `width:${this.size};height:${this.size};margin-top:-50%;margin-left:-50%;border-radius:50%;border:${this.borderSize} solid ${this.border};` //background-color:${this.bg};
        this.shape.setAttribute('style', shapeStyle)
    }

    speed_morph() {
        const min = 0.3
        const max_distance = 500
        const total = this.dist / max_distance
        return Number(Math.min(total, min).toFixed(2))
    }

    update() {
        const speed_morph = this.speed_morph()
        this.scale += (speed_morph - this.scale) * this.lerp

        this.position.x = lerp(this.mouse.x, this.x, this.lerp)
        this.position.y = lerp(this.mouse.y, this.y, this.lerp)

        this.degrees = Math.atan2(this.dy, this.dx) * 180 / Math.PI
    }

    render() {
        this.update()
        gsap.set(this.container, {
            force3D: true,
            x: this.x,
            y: this.y
        })
        gsap.set(this.img, {
            rotate: `${-this.rotation}deg`
        })
        gsap.set(this.shape, {
            force3D: true,
            rotate: `${this.rotation}deg`,
            scaleX: 1 + this.scale,
            scaleY: 1 - this.scale
        })
    }

    get x() {
        return this.position.x.toFixed(this.precision)
    }

    get rotation() {
        return this.degrees.toFixed(this.precision)
    }

    get y() {
        return this.position.y.toFixed(this.precision)
    }

    get dx() {
        return this.mouse.x - this.position.x
    }

    get dy() {
        return this.mouse.y - this.position.y
    }

    get dist() {
        return Math.hypot(this.dx, this.dy)
    }
}